import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

/* IE11 Polyfills */
import 'core-js';
import 'react-app-polyfill/ie11';

import App from './App';
import store from './store';

const rootElem = document.getElementById('root');
const render = Component => {
  return ReactDOM.render(
    <Provider store={store}>
      <Component />
    </Provider>,
    rootElem,
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}
