let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://0lqrbqizj0.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://8fsbe9c7ee.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://u2a8o4tgvf.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.evolution.forwoodsafety.com',
 forwood_id_url: 'https://id.evolution.forwoodsafety.com?redirect_uri=https://geoeditor.evolution.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.evolution.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_ivGzssITx',
   userPoolWebClientId: '28v1300qopcf3fs2u3892b6tci',
   cookieStorage: {
     domain: '.evolution.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
