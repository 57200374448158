/**
 * Initializes Intercom with the provided domain and optional payload data.
 * @param {string} domain - The domain associated with the Intercom configuration.
 * @param {string} currentCookieLanguage - Current language selected by the user stored in cookie.
 * @param {Object} payload - (Optional) Additional data to include in the Intercom configuration.
 */
export function intercomMessenger(domain, currentCookieLanguage, payload = {}) {
  // Construct the Intercom configuration object
  const intercomConfig = {
    app_id: 'tlmqy4u1',
    api_base: 'https://api-iam.intercom.io',
    domain,
    ...payload,
  };

  // Boot Intercom with the constructed configuration
  window.Intercom('boot', intercomConfig);

  // Update language override after booting Intercom
  window.intercomSettings = {
    language_override: currentCookieLanguage,
    ...intercomConfig,
  };

  // Call Intercom's update method to apply the language change
  if (window.Intercom) {
    window.Intercom('update', window.intercomSettings);
  }
}
